import { Component, EventEmitter, Input, Output, ViewChild, HostListener } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';
import { DatePipe } from '@angular/common';
import templateString from './daily-stock-product.component.html';
import styleString from './daily-stock-product.component.scss';
import { ProductDailyStockService } from '../../services/product-daily-stock.service';
import { DateAdapter } from '@angular/material/core';
import CustomAlert from '../../models/custom-alert.model';
import { AccumulativePricePipe } from '../../pipes/accumulative-price.pipe';
import { SessionService } from '../../services/session.service';
import * as moment from "moment-timezone";

@Component({
  selector: 'app-daily-stock-product',
  template: templateString,
  styles: [ styleString ],
  providers:  [ ProductDailyStockService, DatePipe, AccumulativePricePipe, SessionService ]
})

export class DailyStockProductComponent {
  @Input() parentProduct:any;
  @Input() meta:any = {};
  @Input() lineItem = null;
  @Output() selectedDailyStockData = new EventEmitter<{variants: any, price: string,showAddCartBtn: boolean, dailyStockMeta: any}>();
  @ViewChild(MatDatepicker) datePicker: MatDatepicker<any>;

  dailyStockProductForm: FormGroup;
  preferredDate: FormGroup;
  productList:any = [];
  // it will hold all variants, total quantity against product, errors against product
  productsData:any = {variants: {}, errors: {}};
  basePrice = 0;
  moment: any = moment;
  toggle = {};

  constructor(
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private productDailyStockService: ProductDailyStockService,
    private dateAdapter: DateAdapter<Date>,
    protected accumulativePrice: AccumulativePricePipe,
    protected sessionService: SessionService,
  ){
    this.dateAdapter.setLocale('en-GB');
  }

  //  Close the date picker when clicking outside
   @HostListener('document:click', ['$event'])
   closeCalendar(event: Event): void {
     if ((event.target as Element).className.split('-')[0] != 'mat') this.datePicker.close();
   }

  ngOnInit() {
    this.resetFormWithPreferredDate();

    if(this.lineItem?.attributes?.product?.data?.attributes?.effective_date){
      this.preferredDate.patchValue({ date: this.lineItem.attributes.product.data.attributes.effective_date});
      this.populateDailyStockVariants(this.preferredDate.controls.date);
    }
  }

  formattedDate(date, format = 'yyyy-MM-dd'){
    return this.datePipe.transform(date, format);
  }

  populateDailyStockVariants(date){
     this.productDailyStockService.availableProducts(this.parentProduct.id, this.formattedDate(date.value)).subscribe((data) => {
      if (data){
        this.initializeAndResetProducts();
        this.productList = data['data'];
        this.productList.forEach( product => {
          this.dailyStockProductForm.addControl(product.id.toString(), this.formBuilder.array([]));
          let masterVariant = product.attributes.variants.data.length === 1;

          if(masterVariant) { // show only variant which must be master variant
            let variant = product.attributes.variants.data[0];
            (<FormArray>this.dailyStockProductForm.get(product.id.toString())).push(this.setDailyStockVariantControl(product, variant));
          } else { // for other variants
            product.attributes.variants.data.forEach(variant => {
              if(!variant.attributes.is_master) // skip master variant
                (<FormArray>this.dailyStockProductForm.get(product.id.toString())).push(this.setDailyStockVariantControl(product, variant));
            });
          }
        });
      }
    });
    this.emitDailyStock({variants: [], price: this.parentProduct.attributes.price_values.final_price, showAddCartBtn: true, dailyStockMeta: {disableAddToCart: true}});
  }
  toggleClass(event) {
    if(event.target) {
      //console.log(event.target.closest('.ds-toggler').getAttribute('id'));
      let items = document.querySelectorAll('.ds-toggler');
      items.forEach(function (item) {
        item.classList.remove('active');
      });
      // Add the class to the clicked element
      event.target.closest('.ds-toggler').classList.add('active');
    }
  }
  emitDailyStock(data = null){
    this.selectedDailyStockData.emit(data || {variants: [], price: this.parentProduct.attributes.price_values.final_price, showAddCartBtn: false, dailyStockMeta: {disableAddToCart: true}});
  }

  changeQuantity(product: any, variantIndex: number, operator: string = '+') {
    let variantControl = (<FormArray>this.dailyStockProductForm.get(product.id)).at(variantIndex);
    let quantityControl = variantControl.get('quantity');

    let orderQuantity = this.dailyStockProductForm.controls.orderQuantity.value;
    let quantity = quantityControl.value; // get previous quantity value from control

    if(operator === "+"){
      quantity += 1;
      orderQuantity += 1;
      this.basePrice += parseFloat(variantControl.get('price').value);
    }
    else if(quantity !== 0){
      quantity -= 1;
      orderQuantity -= 1;
      this.basePrice -= parseFloat(variantControl.get('price').value);
    }

    // update values after quantity computation
    quantityControl.setValue(quantity);
    this.dailyStockProductForm.controls.orderQuantity.setValue(orderQuantity);

    if (quantity > 0)
      this.productsData.variants[product.id+variantIndex] = variantControl.value;
    else if(this.productsData.variants[product.id+variantIndex])
      delete this.productsData.variants[product.id+variantIndex];

    if(quantity != 0 && quantity < product["attributes"]["minimum_order_quantity"])
      this.productsData.errors[variantControl.value.id] = CustomAlert.text("MOQ_QUANTITY", { moq_quantity: product["attributes"]["minimum_order_quantity"] }).text;
    else if(quantity != 0 && (quantity % product["attributes"]["pack_size"] != 0 || product["attributes"]["minimum_order_quantity"] > quantity))
      this.productsData.errors[variantControl.value.id] = CustomAlert.text("MOQ_TEXT", { moq: product["attributes"]["minimum_order_quantity"] }).text;
    else if(this.productsData.errors[variantControl.value.id])
      delete this.productsData.errors[variantControl.value.id];

    let dailyStockMeta = {};
    dailyStockMeta["errors"] = Object.values(this.productsData.errors);
    dailyStockMeta["disableAddToCart"] = (orderQuantity == 0 || dailyStockMeta["errors"].length > 0);
    this.emitDailyStock({
      variants: Object.values(this.productsData.variants),
      price: this.basePrice.toString(),
      showAddCartBtn: true,
      dailyStockMeta: dailyStockMeta
    });
  }

  setDailyStockVariantControl(product, variant){
    let quantityDisabled = (variant.attributes.quantity === 0 || !product.attributes.stock_status);
    let addedQuantity = 0;

    if(this.lineItem && this.lineItem.attributes.variant_id.toString() == variant.id)
      addedQuantity = this.lineItem.attributes.quantity;

    let variantControls = this.formBuilder.group({
      id: variant.id.toString(),
      name: variant.attributes.options_text || product.attributes.name,
      quantity_disabled: quantityDisabled,
      price: variant.attributes.price_values.price,
      quantity: addedQuantity,
      options: {}
    });

    if(this.lineItem && this.lineItem.attributes.variant_id.toString() == variant.id){
      this.basePrice = parseFloat(this.lineItem.attributes.price_values.amount);
      this.dailyStockProductForm.controls.orderQuantity.setValue(addedQuantity);

      this.emitDailyStock({
        variants: [variantControls.value],
        price: this.basePrice.toString(),
        showAddCartBtn: true,
        dailyStockMeta: {disableAddToCart: false}
      });
    }

    return variantControls;
  }

  resetFormWithPreferredDate(){
    this.preferredDate = this.formBuilder.group({ date: [''] });
    this.initializeAndResetProducts();
    this.emitDailyStock();
  }

  initializeAndResetProducts(){
    this.productList.forEach( (product) => {
      this.dailyStockProductForm.get(product.id)['controls'].forEach((variant) => { variant.reset(); });
      this.dailyStockProductForm.reset();
    });

    this.productList = [];
    this.dailyStockProductForm = this.formBuilder.group({ orderQuantity: 0 });
    this.productsData =  { variants: {}, errors: {} };
    this.basePrice = 0;
  }

  dateFilter = (date): boolean => {
    date = this.datePipe.transform(date, 'yyyy-MM-dd');

    return (moment(date).isSameOrAfter(moment(), "day") && this.parentProduct.attributes.stock_dates.includes(date));
  };
}
